<template>
    <div id="app">
        <Transition
            enter-active-class="transition ease-out duration-500"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-500"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <Suspense>
                <template #fallback>
                    <LoadingScreen />
                </template>
                <slot />
            </Suspense>
        </Transition>
    </div>
</template>

<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#__nuxt {
  height: 100%;
  width: 100%;
  position: relative;
}

#app {
  position: relative;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}
</style>
